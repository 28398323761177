import * as style from "./women.module.scss"
import React, { useState } from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import loadable from "@loadable/component"
import Layout from "../components/Layout"
import {
  getAllLocales,
  getBackgroundImage,
  getCurrentLanguagePage,
  getGatsbyImage,
} from "../plugins/common"

const Slogan = loadable(() => import("../components/Slogan"))
const Shelf = loadable(() => import("../components/Shelf"))
const PartnerBanner = loadable(() => import("../components/PartnerBanner"))
const Pros = loadable(() => import("../components/homepage/Pros"))
const FaqInPage = loadable(() => import("../components/FaqInPage"))
const Banner = loadable(() => import("../components/homepage/Banner"))
const Browse = loadable(() => import("../components/resources/Browse"))
const BannerCall = loadable(() => import("../components/BannerCall"))
const PartnerProfile = loadable(() => import("../components/PartnerProfile"))

const headerLeftShape = "../images/women/header-left.png"
const headerRightShape = "../images/women/header-right.png"
const shelfLeftShape = "../images/women/shelf-left.png"
const bannerLeftShape = "../images/women/banner-left.png"
const bannerRightShape = "../images/women/banner-right.png"
const browseLeftShape = "../images/women/browse-left.png"
const browseRightShape = "../images/women/browse-right.png"
const banner2RightEmpty =
  "../images/stand-alone-child/standAloneChildBannerRightEmpty.webp"
const banner2RightFull =
  "../images/stand-alone-child/standAloneChildBannerRightFull.webp"
const profileLeftShape = "../images/women/profile-left.png"

function WomenPage({ pageContext, location }) {
  const { node: data, general, form } = pageContext
  const [metaData, setMetaData] = useState(data?.meta)

  const headerImage = getGatsbyImage(data.headerBlock.image[0])
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const ogImage = getBackgroundImage(data.headerBlock.image[0])
  const keyLabels = general.allStrapiRessource.nodes.find(
    node => node.locale === lang
  )
  const aleaToken = general.aleaToken
  const categories = general.categories.filter(c => c.type === "women")

  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...form.freeQuote,
  }
  // console.log("data: ", form, data)

  return (
    <div className={`${style.women}`}>
      <Layout
        props={general}
        meta={metaData}
        localizations={getAllLocales(data.localizations, data.locale)}
        location={location}
        lang={lang}
        ogImage={ogImage}
        additionalClass={"women"}
        hideNewsletter={true}
        token={aleaToken}
        footerForm={enableFooterForm ? footerForm : null}
      >
        <div className="overflow-hidden">
          <Slogan props={data.headerBlock} specialColor="women-red">
            <div id="left" className={`${style.womenHeader} position-relative`}>
              <StaticImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className={`${style.leftShape} position-absolute`}
                src={headerLeftShape}
                alt=""
                layout="constrained"
              />
            </div>
            <div
              id="right"
              className={`${style.womenHeader} position-relative`}
            >
              <div className="imageZoom d-unset">
                <GatsbyImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className={`${style.image} imageAni`}
                  image={headerImage}
                  alt="alea"
                />
              </div>
              <div className="">
                <StaticImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className={`${style.rightShape} position-absolute`}
                  src={headerRightShape}
                  alt=""
                  layout="constrained"
                />
              </div>
            </div>
          </Slogan>
          <div className={`${style.shelf} position-relative`}>
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className={`${style.shelfLeft} position-absolute`}
              src={shelfLeftShape}
              alt=""
              layout="constrained"
            />

            <Shelf
              title={data.whyAlea.title}
              list={data.whyAlea.imageContentCta}
              startFrom="left"
              dashColor={"bg-women-sharp"}
              specialColor="women-red"
              lang={lang}
            />
          </div>
          <PartnerBanner
            bgColor="bg-mint"
            titleColor="women-red"
            title={data.bannerPartner.title}
            logo={data.bannerPartner.logo}
          />
          <Pros
            props={data.sellingPoints}
            lang={lang}
            additionalClass="women"
            specialColor="women-red"
          />
          <div className={`${style.space120}`}></div>
          <FaqInPage
            title={data.faq.sectionTitle}
            faq={data.faq.questionAnswer}
            meta={metaData}
            setMeta={setMetaData}
            titleColor="women-red"
            addtionalClass="women"
          />
          <div className={`${style.space120}`}></div>

          <div className={`${style.banner} position-relative`}>
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className={`${style.bannerLeftShape} position-absolute`}
              src={bannerLeftShape}
              alt=""
              layout="constrained"
            />
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className={`${style.bannerRightShape} position-absolute`}
              src={bannerRightShape}
              alt=""
              layout="constrained"
            />
            <Banner props={data.banner} lang={lang} additionalClass="women" />
          </div>
          <div className={`${style.browse} position-relative`}>
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className={`${style.browseLeftShape} position-absolute`}
              src={browseLeftShape}
              alt=""
              layout="constrained"
            />
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className={`${style.browseRightShape} position-absolute`}
              src={browseRightShape}
              alt=""
              layout="constrained"
            />
            <Browse
              props={data.womenBrowse}
              dashColor="bg-women-sharp"
              labels={keyLabels}
              lang={lang}
              token={aleaToken}
              categories={categories}
              hideToolBox={true}
              additionalClass={"women"}
            />
          </div>
          <BannerCall
            props={data.banner2}
            bgColor="bg-daffodil"
            additionalClass="women"
            lang={lang}
          >
            <div className={`${style.banner2} position-relative`}>
              <StaticImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className={`${style.rightEmpty} position-absolute`}
                src={banner2RightEmpty}
                alt=""
                layout="constrained"
              />
              <StaticImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className={`${style.rightFull} position-absolute`}
                src={banner2RightFull}
                alt=""
                layout="constrained"
              />
              <div className="imageZoom d-unset">
                <GatsbyImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className={`${style.image} imageAni position-absolute`}
                  image={getGatsbyImage(data.banner2.image[0])}
                  alt="alea"
                />
              </div>
            </div>
          </BannerCall>
          <div className={`${style.space120}`}></div>
          <div className={`${style.space120} d-lg-none d-block`}></div>
          <div className={`${style.profile} position-relative`}>
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className={`${style.profileLeftShape} position-absolute`}
              src={profileLeftShape}
              alt=""
              layout="constrained"
            />
            <PartnerProfile
              context={data.support}
              specialTitleColor="women-red"
            ></PartnerProfile>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default WomenPage
