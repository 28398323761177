// extracted by mini-css-extract-plugin
export var alertRed = "women-module--alert-red--Mb4sY";
export var banner = "women-module--banner--1mPxS";
export var banner2 = "women-module--banner2--uNwyW";
export var bannerLeftShape = "women-module--bannerLeftShape--8Lczn";
export var bannerRightShape = "women-module--bannerRightShape--rAi1u";
export var bgBarGrey = "women-module--bg-bar-grey--fmpbR";
export var bgBeige = "women-module--bg-beige--OIokG";
export var bgCopper = "women-module--bg-copper--CYD1a";
export var bgDaffodil = "women-module--bg-daffodil--Q9Aac";
export var bgGreyText = "women-module--bg-grey-text--7154n";
export var bgMing = "women-module--bg-ming--xf1Xb";
export var bgMint = "women-module--bg-mint--dBO3q";
export var bgRed = "women-module--bg-red--JpGM1";
export var bgRedTrans = "women-module--bg-red-trans--zvteb";
export var bgSand = "women-module--bg-sand--tuAp5";
export var bgSoftSun = "women-module--bg-softSun--6TKba";
export var bgTeal = "women-module--bg-teal--nXZJM";
export var bgWhite = "women-module--bg-white--sNXSC";
export var bgWomenSharp = "women-module--bg-women-sharp--2Fyss";
export var blackText = "women-module--black-text--mrKJK";
export var browse = "women-module--browse--E7hnj";
export var browseLeftShape = "women-module--browseLeftShape--J444O";
export var browseRightShape = "women-module--browseRightShape--WoOlG";
export var copper = "women-module--copper--kgdau";
export var corners = "women-module--corners--nW593";
export var cursorNormal = "women-module--cursor-normal--2VCiZ";
export var cursorPointer = "women-module--cursor-pointer--yxbUi";
export var dUnset = "women-module--d-unset--nl4lY";
export var darkGrey = "women-module--dark-grey--Is58Z";
export var dash = "women-module--dash--ORkDZ";
export var flex = "women-module--flex--f9bvO";
export var flex1 = "women-module--flex-1--rkQD3";
export var fontSize12 = "women-module--font-size-12--jzbZc";
export var fontSize20 = "women-module--font-size-20--MT-q0";
export var greyPlaceholder = "women-module--grey-placeholder--huHn6";
export var greyText = "women-module--grey-text--Lcqk5";
export var h1sizing = "women-module--h1sizing--pwTlA";
export var h2sizing = "women-module--h2sizing--I8WJZ";
export var hidden = "women-module--hidden--agADd";
export var image = "women-module--image--UNN1g";
export var imageAni = "women-module--imageAni--Jwpfk";
export var imageZoom = "women-module--imageZoom--gErHR";
export var imageZoomGatsby = "women-module--imageZoomGatsby--KsoZU";
export var italic = "women-module--italic--uqnfl";
export var leftShape = "women-module--leftShape--mwCgv";
export var lightGrey = "women-module--light-grey--G5zD+";
export var logo = "women-module--logo--URD+K";
export var lora = "women-module--lora--avuDR";
export var loraBold = "women-module--lora-Bold--f8OEj";
export var loraBoldItalic = "women-module--lora-BoldItalic--UtSr5";
export var loraMedium = "women-module--lora-Medium--p8ldo";
export var loraSemiBold = "women-module--lora-SemiBold--2B665";
export var main = "women-module--main--Dti9q";
export var ming = "women-module--ming--ytaXd";
export var mingHover2 = "women-module--ming-hover-2--OOCCA";
export var modalOpen = "women-module--modal-open--8LQ5Z";
export var noUnderline = "women-module--no-underline--JBQO1";
export var openSans = "women-module--openSans--2UZLA";
export var openSansBold = "women-module--openSans-Bold--g7WaB";
export var openSansSemiBold = "women-module--openSans-SemiBold--hazDF";
export var profile = "women-module--profile--LwRwJ";
export var profileLeftShape = "women-module--profileLeftShape--BOEm3";
export var quotation = "women-module--quotation--HCqHs";
export var rightEmpty = "women-module--rightEmpty--EeuHm";
export var rightFull = "women-module--rightFull--Hb2Qz";
export var rightShape = "women-module--rightShape--2ixV6";
export var round = "women-module--round--xNWAs";
export var roundCorners = "women-module--round-corners--H3S8S";
export var shelf = "women-module--shelf--ct2R+";
export var shelfLeft = "women-module--shelfLeft--C4Obi";
export var softCorners = "women-module--soft-corners--CMUPJ";
export var softShadows = "women-module--soft-shadows--eft9X";
export var softerCorners = "women-module--softer-corners--3rahE";
export var softyCorners = "women-module--softy-corners--wcNQb";
export var space120 = "women-module--space120--ZXbBK";
export var tableShadow = "women-module--table-shadow--IuMkU";
export var teal = "women-module--teal--Dcbgc";
export var topNavShadow = "women-module--top-nav-shadow--PRaDQ";
export var uppercase = "women-module--uppercase--Ookat";
export var wFull = "women-module--w-full--Xoi5b";
export var white = "women-module--white--EIYam";
export var women = "women-module--women--YcD+-";
export var womenHeader = "women-module--womenHeader--25rjy";
export var womenRed = "women-module--women-red--9B+AV";
export var womenSharp = "women-module--women-sharp--LTucn";